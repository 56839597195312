import React from "react";
import "./table.css";

const ReadTable = ({ datas }) => {
  const item = {
    "_id": "660140f94d7fc6a59c278838",
    "email": "santhdilip97@gmail.com",
    "name": "Santhosh Kumar",
    "mobile": "7904901956",
    "department": "Mechanical",
    "dob": "1997-11-10T00:00:00.000Z",
    "institution": "KCET",
    "roles": "Website/App Developer",
    "gender": "Male",
    "whatsapp": "7904901956",
    "createdAt": "25-3-2024",
    "__v": 0
  };

  const arrayWithItem = Array(60).fill(item);
  return (
    <table>
      <tr>
        <th>S.no</th>
        <th>Name</th>
        <th>Email</th>
        <th>Mobile</th>
        <th>Role</th>
        <th>DOB</th>
        <th>Gender</th>
        <th>College</th>
        <th>Department</th>
      </tr>
      {datas &&
        datas.map((data, index) => (
          <tr>
            <td>{index + 1}</td>
            <td>{data.name}</td>
            <td>{data.email}</td>
            <td>{data.mobile}</td>
            <td>{data.roles}</td>
            <td>{data.dob.split("T")[0]}</td>
            <td>{data.gender}</td>
            <td>{data.institution}</td>
            <td>{data.department}</td>
          </tr>
        ))}
    </table>
  );
};

export default ReadTable;
