import React from 'react'
import {AppBar,Toolbar, Typography} from "@mui/material"
import { NavLink } from 'react-router-dom'

const Navbar = () => {
  return (
    <AppBar position='sticky' sx={{backgroundColor:"rgba(255,255,255,0.3)"}}>
        <Toolbar>
            <NavLink to="/admin">
            <img src='assets/images/helloLogo2.png' width="100px" style={{borderRadius:"5px"}}/>
            </NavLink>
            <Typography ml={1} sx={{fontSize:{xs:"18px",md:"25px"},fontWeight:"600",color:"#131c48",display:{xs:"none",md:"block"}}}>Hello Technologies</Typography>
        </Toolbar>
    </AppBar>
  )
}

export default Navbar