import React from "react";
import ModalLayout1 from "./ModalLayout1";
import {Box, CircularProgress, LinearProgress } from "@mui/material";

const LoadingModal = ({display}) => {
  return (
    <ModalLayout1 displayCont={display} bgcolor="rgba(232, 35, 37, 0.5)" >
      <Box width="100%" >
       <CircularProgress color="success" />
      </Box>
    </ModalLayout1>
  );
};

export default LoadingModal;
