import React ,{useState,useEffect}from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import ModalLayout from "../Utills/ModalLayout";
import {useNavigate} from "react-router-dom"
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

const Login = ({onlogin}) => {
  const  navigate= useNavigate()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const uName="HellowTech"
  const uPassword="HellowTech123"

  const showToast = (message, error) => {
    Toastify({
      text: message,
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: "top", // or "bottom"
      position: "right", // Here you can specify the position
      offset: {
        x: 50, // horizontal axis - can be a number or a string indicating percentage
        y: 60, // vertical axis - can be a number or a string indicating percentage
      },
      style: {
        background: error
          ? "red"
          : "linear-gradient(to right, #00b09b, #96c93d)",
        color: "#fff",
      },
    }).showToast();
  };

  const verifyLogin=()=>{
    if(username===uName && password===uPassword){
      onlogin()
      navigate("/admin")
    }else{
      showToast("Invalid UserName or Password",true)
    }
  }
  return (
    <Box height="100vh">
      <Box
        height="60vh"
        ml="auto"
        mr="auto"
        mt={3}
        sx={{ width: { xs: "80%", md: "40%" } }}
      >
        <ModalLayout>
          <Box
            height="100%"
            width="100%"
            pt={3}
            pb={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography fontSize="clamp(22px,2vw,28px)" fontWeight="600" mb={3}>Admin Login</Typography>
            <TextField sx={{mb:3,width:"80%"}} label="User Name" type="text" value={username} onChange={(e)=>setUsername(e.target.value)}/>
            <TextField sx={{mb:3,width:"80%"}} label="Password" type="password" value={password} onChange={(e)=>setPassword(e.target.value)}/>
            <Button sx={{width:"40%",ml:3,backgroundColor:"#861618"}} variant="contained" onClick={verifyLogin}>
                <Typography color="">Login</Typography>
            </Button>
          </Box>
        </ModalLayout>
      </Box>
    </Box>
  );
};

export default Login;
