import { MenuItem, Select, Box, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState, Suspense } from "react";
import ReadTable from "./ReadTable";
import { client } from "../Utills/Client";
import LoadingModal from "../Utills/LoadingModal";
const LazyTable = React.lazy(() => import("./ReadTable"));

//bg color change
const Read = () => {
  const [search, setSearch] = useState("");
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [openDialog, setOpenDialog] = useState(false)
  const [load, setLoad] = useState(false)

  const getStudents = async () => {
    let response = await client.get("/user");
    setStudents(response.data);
    setFilteredStudents(response.data);
    console.log(response.data);
  };

  useEffect(() => {
    getStudents();
  }, []);

  const handleChange = (e) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      setFilteredStudents(students);
    } else {
      const filtererd = students.filter((data) => {
        const lowerText = e.target.value.toLowerCase();
        if (data.roles.toLowerCase() == lowerText) {
          return data;
        }
      });
      setFilteredStudents(filtererd);
    }
  };

  const deleteAllItems = async()=>{
    setOpenDialog(false)
    setLoad(true)
    try {
      const response = await client.delete("/user")
      if(response.status!=200){
        alert("The user request Failed")
        setLoad(false)
      }else{
        alert("Delete Successfully")
        getStudents()
        setLoad(false)
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <Box
      p={3}
      sx={{ height: "150vh", backgroundColor: "white", textAlign: "left" }}
    >
      <Grid container spacing={2} my={3}>
        <Grid item md={4} xs={12}>
          <Select
            displayEmpty
            value={search}
            onChange={handleChange}
            fullWidth
          >
            <MenuItem value="" disabled>
              Select Roles
            </MenuItem>
            <MenuItem value="Website/App Developer">
              Website/App Developer
            </MenuItem>
            <MenuItem value="Digital Marketing">Digital Marketing</MenuItem>
            <MenuItem value="Graphic Designer">Graphic Designer</MenuItem>
          </Select>
        </Grid>
        <Grid item md={4} xs={12}>
          <Button fullWidth variant="contained" color="error" sx={{height:"100%"}} onClick={()=>setOpenDialog(true)}>Delete All</Button>
        </Grid>
      </Grid>
      <Suspense fallback={<LoadingModal display={true} />}>
        <LazyTable datas={filteredStudents} />
      </Suspense>
      <Dialog open={openDialog}>
        <DialogTitle>Delete All Items</DialogTitle>
        <DialogContent>Are you sure you want to delete all the datas in the table?</DialogContent>
        <DialogActions >
          <Button color="error" onClick={deleteAllItems}>Yes</Button>
          <Button onClick={()=>setOpenDialog(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={load}>
        <CircularProgress color="error"/>
      </Backdrop>
    </Box>
  );
};

export default Read;
