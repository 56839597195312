import React from "react";
import { Paper, Box } from "@mui/material";

const ModalLayout = ({ children }) => {
  return (
    <Box>
      <Paper
        elevation={10}
        sx={{
          height: "100%",
          width: "100%",
          background:
            "linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.3))",
            backdropfilter: "blur(10px)",WebkitBackdropFilter:"blur(10px)"
        }}
      >
        {children}
      </Paper>
    </Box>
  );
};

export default ModalLayout;
