import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Select,
  Button,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
import ModalLayout from "../Utills/ModalLayout";
import PersonIcon from "@mui/icons-material/Person";
import InstagramIcon from '@mui/icons-material/Instagram';
import YoutubeIcon from '@mui/icons-material/YouTube'
import FacebookIcon from '@mui/icons-material/Facebook'
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import SchoolIcon from "@mui/icons-material/School";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import validator from "validator";
import LoadingModal from "../Utills/LoadingModal";
import { client } from "../Utills/Client";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

const Form = () => {
  //state to everything
  const [values, setValues] = useState({
    name: "",
    email: {
      value: "",
      error: false,
      errortext: "",
    },
    mobile: {
      value: "",
      error: false,
      errorText: "",
    },
    whatsapp: {
      value: "",
      error: false,
      errorText: "",
    },
    gender: "",
    dob: "",
    institution: "",
    department: "",
    roles: "",
  });

  //ref
  const dateRef = useRef(null);

  //other states
  const [loader, setLoader] = useState(false);
  const [dateShow, setDateShow] = useState(false);

  const showDateInput = () => {
    setDateShow(true);
    dateRef.current.focus();
  };

  //handlechange to everything
  const handleChange = (e) => {
    switch (e.target.name) {
      case "email":
        setValues((prev) => ({
          ...prev,
          email: { value: e.target.value, error: false },
        }));
        break;
      case "mobile":
        let numericValue = e.target.value.replace(/[^0-9]/g, "");
        if (numericValue.length <= 10) {
          setValues((prev) => ({
            ...prev,
            mobile: { value: numericValue, error: false, errorText: "" },
          }));
        }
        break;
      case "whatsapp":
        let numeric = e.target.value.replace(/[^0-9]/g, "");
        if (numeric.length <= 10) {
          setValues((prev) => ({
            ...prev,
            whatsapp: { value: numeric, error: false, errorText: "" },
          }));
        }
        break;
      case "name":
      case "dob":
      case "gender":
      case "department":
      case "institution":
      case "roles":
        setValues((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
        break;
      default:
        setValues((prev) => prev);
    }
  };

  const showToast = (message, error) => {
    Toastify({
      text: message,
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: "top", // or "bottom"
      position: "right", // Here you can specify the position
      offset: {
        x: 20, // horizontal axis - can be a number or a string indicating percentage
        y: 60, // vertical axis - can be a number or a string indicating percentage
      },
      style: {
        background: error
          ? "red"
          : "linear-gradient(to right, #00b09b, #96c93d)",
        color: "#fff",
      },
    }).showToast();
  };

  const emailError = async () => {
    
    if (!validator.isEmail(values.email.value)) {
      setValues((prev) => ({
        ...prev,
        email: {
          value: values.email.value,
          error: true,
          errortext: "Enter a Valid Email",
        },
      }));
      
    }
  
  };

  const emailFocus = () => {
    setValues((prev) => ({
      ...prev,
      email: { value: values.email.value, error: false },
    }));
  };

  const mobileError = () => {
    if (values.mobile.value.length != 10) {
      setValues((prev) => ({
        ...prev,
        mobile: {
          value: values.mobile.value,
          error: true,
          errorText: "Enter a valid Number",
        },
      }));
    }
  };

  const mobileFocus = () => {
    setValues((prev) => ({
      ...prev,
      mobile: { value: values.mobile.value, error: false },
    }));
  };

  const whatsapperror = () => {
    if (values.mobile.value.length != 10) {
      setValues((prev) => ({
        ...prev,
        whatsapp: {
          value: values.whatsapp.value,
          error: true,
          errrorText: "Enter a valid number",
        },
      }));
    }
  };

  const whatsappFocus = () => {
    setValues((prev) => ({
      ...prev,
      whatsapp: { error: false, focus: true },
    }));
  };

  const quickSetWhatsapp = () => {
    if (values.mobile.value == "") {
      setValues((prev) => ({
        ...prev,
        mobile: {
          value: values.mobile.value,
          error: true,
          errorText: "Enter the Mobile Number First",
        },
      }));
      return;
    }
    setValues((prev) => ({
      ...prev,
      whatsapp: { value: values.mobile.value, error: false, focus: false },
    }));
  };

  const postData = async () => {
    setLoader(true);
    try {
      const response = await client
        .post("/user", {
          name: values.name,
          email: values.email.value,
          mobile: values.mobile.value,
          gender: values.gender,
          department: values.department,
          dob: values.dob,
          institution: values.institution,
          roles: values.roles,
          whatsapp: values.whatsapp.value,
        })
        .then((res) => {
          if (res.status == 204) {
            console.log("error");
            showToast("Email already Exists", true);
            setLoader(false);
          } else if(res.status==200){
            showToast("Registered Successfully! Welcome to Hello Technology", false);
            setLoader(false);
          }
          else {
            showToast("Seomething Went Wrong", true);
            setLoader(false);
          }
        });
    } catch (err) {
      console.log(err);
      showToast("Something went wrong", true);
      setLoader(false);
    }
    
  };
//updated
  const clearForm = () => {
    setValues({
      name: "",
      email: {
        value: "",
        error: false,
        errortext: "",
      },
      mobile: {
        value: "",
        error: false,
        errorText: "",
      },
      whatsapp: {
        value: "",
        error: false,
        errorText: "",
      },
      gender: "",
      dob: "",
      institution: "",
      department: "",
      roles: "",
    });
  };

  const handleClick = () => {
    
    if (
      values.name &&
      values.email.value &&
      !values.email.error &&
      values.mobile.value &&
      !values.mobile.error &&
      values.gender &&
      values.dob &&
      values.institution &&
      values.roles && values.whatsapp
    ) {
      postData();
    }else{
      showToast("Fill all the Fields",true)
      setLoader(false)
    }
   
  };

  return (
    <>
      <Box height="100vh" width="100%" >
        <Box
          height="60vh"
          ml="auto"
          mr="auto"
          mt={3}
          sx={{ width: { xs: "90%", md: "40%" } }}
        >
          <ModalLayout>
            <Box height="100%" width="100%" pt={3} pb={3}>
              <img src="assets/images/helloLogo2.png" width="200px" />
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h5"
                  textAlign="left"
                  ml={3}
                  mr={1}
                  mt={3}
                  sx={{ fontSize: "clamp(25px,2vw,30px)", fontWeight: "600" }}
                >
                  Profile
                </Typography>
                <AccountBoxTwoToneIcon
                  style={{
                    paddingTop: "24px",
                    fontSize: "clamp(23px,2vw,30px)",
                  }}
                />
              </Box>
              <Grid container spacing={2} mb={1} mt={2} mr={3} p={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="text"
                    label="Full Name"
                    value={values.name}
                    name="name"
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Select
                    value={values.gender}
                    onChange={handleChange}
                    name="gender"
                    fullWidth
                    displayEmpty
                    sx={{ textAlign: "left" }}
                  >
                    <MenuItem value="" disabled>
                      Gender
                    </MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                </Grid>
                <Grid item md={6} xs={12} display={dateShow ? "block" : "none"}>
                  <TextField
                    value={values.dob}
                    onChange={handleChange}
                    ref={dateRef}
                    name="dob"
                    type="date"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} display={dateShow ? "none" : "block"}>
                  <Button
                    size="large"
                    color="inherit"
                    variant="outlined"
                    fullWidth
                    sx={{
                      height: { xs: "60px", md: "100%" },
                      borderColor: "#545454",
                      textTransform: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      paddingLeft: 1.5, // Adjust the left padding
                      paddingRight: 1.5,
                    }}
                    onClick={showDateInput}
                  >
                    <Typography
                      width="100%"
                      textAlign="left"
                      sx={{ color: "#343434" }}
                    >
                      Data of Birth
                    </Typography>
                    <CalendarMonthIcon style={{ fontSize: "25px" }} />
                  </Button>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    type="text"
                    label="Mobile No"
                    value={values.mobile.value}
                    onChange={handleChange}
                    error={values.mobile.error}
                    helperText={
                      values.mobile.error ? values.mobile.errorText : null
                    }
                    onBlur={mobileError}
                    onFocus={mobileFocus}
                    name="mobile"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    type="email"
                    label="Email Id"
                    value={values.email.value}
                    onChange={handleChange}
                    error={values.email.error}
                    helperText={
                      values.email.error ? "* Enter a Valid Email" : null
                    }
                    onBlur={emailError}
                    onFocus={emailFocus}
                    name="email"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    type="text"
                    label="Whatsapp No"
                    value={values.whatsapp.value}
                    onChange={handleChange}
                    error={values.whatsapp.error}
                    helperText={
                      values.whatsapp.error ? "Enter a Valid Number" : null
                    }
                    onBlur={whatsapperror}
                    onFocus={whatsappFocus}
                    name="whatsapp"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <WhatsAppIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
           
                <Grid item xs={12} textAlign="right" pr={3}>
                  <FormControlLabel
                    label="Same as Mobile Number"
                    control={
                      <Checkbox color="success" onChange={quickSetWhatsapp} />
                    }
                    labelPlacement="start"
                  />
                </Grid>
               

                <Grid item xs={12} md={6}>
                  <TextField
                    type="text"
                    label="College Name"
                    value={values.institution}
                    onChange={handleChange}
                    name="institution"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SchoolIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="text"
                    label="Department"
                    value={values.department}
                    onChange={handleChange}
                    name="department"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SchoolIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    value={values.roles}
                    onChange={handleChange}
                    name="roles"
                    fullWidth
                    displayEmpty
                    sx={{ textAlign: "left" }}
                  >
                    <MenuItem value="" disabled>
                      Job Type Looking For
                    </MenuItem>
                    <MenuItem value="Website/App Developer">
                      Website/App Developer
                    </MenuItem>
                    <MenuItem value="Digital Marketing">
                      Digital Marketing
                    </MenuItem>
                    <MenuItem value="Graphic Designer">
                      Graphic Designer
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <Grid container spacing={2} mb={1} mr={3} p={2}>
                <Grid item  xs={1} md={2}></Grid>
                <Grid item  xs={5} md={4}>
                  <Button
                    type="submit"
                    color="error"
                    variant="contained"
                    fullWidth
                    onClick={clearForm}
                  >
                    <Typography>Clear</Typography>
                  </Button>
                </Grid>
                <Grid item  xs={5} md={4}>
                  <Button
                    type="submit"
                    color="success"
                    variant="contained"
                    fullWidth
                    onClick={handleClick}
                  >
                    <Typography>Submit</Typography>
                  </Button>
                </Grid>
                <Grid item  xs={1} md={2} ></Grid>
              </Grid>
              <Grid sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>    
  <Typography
    textAlign="left"
    fontSize="clamp(12px,2vw,18px)"
    ml={2}
    mr={2}
  >
    Note: Please check all the fields before submitting
  </Typography>
<Grid sx={{display:'flex',justifyContent:'space-between'}}>

  <IconButton  href="https://www.instagram.com/hellotechnologies?igshid=MTd1eWJtaDE2ZDZ2ZA==" target="_blank" rel="noopener noreferrer">   
   <InstagramIcon  style={{ color: "#833AB4", fontSize: '25px', marginRight: '10px' }} />
</IconButton>
<IconButton  href="https://www.facebook.com/profile.php?id=61555811608882" target="_blank" rel="noopener noreferrer"> <FacebookIcon style={{ color: "#38529A", fontSize: '25px', marginRight: '10px' }} /></IconButton>
 
 
    
    <IconButton  component="a" href="https://www.youtube.com/@HelloTechnologies" target="_blank" rel="noopener noreferrer"><YoutubeIcon style={{ color: "#D31B24", fontSize: '25px' }} /></IconButton>

</Grid>
{/* <IconButton component="a" href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
        <InstagramIcon sx={{ color: 'rgba(0,239,254,255)' }} />
      </IconButton> */}
</Grid>
            </Box>
    
          </ModalLayout>
        </Box>  
      </Box>
      <LoadingModal display={loader} />
    </>
  );
};

export default Form;
