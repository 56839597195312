import logo from './logo.svg';
import './App.css';
import Form from './Components/Form/Form';
import Navbar from './Components/NavBar/Navbar';
import Login from './Components/Admin/Login';
import Read from './Components/Admin/Read';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ProtectRoutes from './Components/Admin/ProtectRoutes';
import LoadingModal from './Components/Utills/LoadingModal';
import { Box } from '@mui/material';
//bg colour changed
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [authChecked, setAuthChecked] = useState(false)

  useEffect(()=>{
    if(localStorage.getItem("Auth")==null){
      setIsLoggedIn(false)
      setAuthChecked(true)
    }else{
      setIsLoggedIn(true)
      setAuthChecked(true)
    }
  })

  const onLogin=()=>{
    setIsLoggedIn(true);
    localStorage.setItem("Auth",true)
  }

  if(!authChecked){
    return <LoadingModal display={true}/>
  }
  
  return (
    <Box sx={{height:{xs:"150vh",md:"150vh"}}}>
      <Navbar/>
     <Routes>
        <Route path='/admin/*' element={isLoggedIn?<ProtectRoutes/>:<Navigate to="/login"/>}/>
        <Route path='/' element={<Form/>}/>
        <Route path='/login' element={<Login onlogin={onLogin}/>}/>
     </Routes>
    </Box>
  );
}

export default App;
